import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as appStateReducer from "../../../store/app-state/reducer";
import { setBreadcrumb } from "../../../store/app-state/actions";
import Translate from "../../../i18n/translate";
import Service from "./maintenance-service";
import MaintenanceHistory from "./maintenance-history-extend";
import Session from "../../../utils/session-info";
import UserService from "../../users/user-service";
import Toast from "../../../components/toast/toast";
import { Grid2 } from "@mui/material";
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import InternalPageTitle from "../../../componentsUI/internalPageTitle"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"
import StyledInputLabel from "../../../componentsUI/styledComponents/styledInputLabel"
import ConectorDialogConfirmation from "../../../componentsUI/dialogComponentConfirmation"
import ConectorInputText from "../../../componentsUI/inputText"
import ConectorInputNumber from "../../../componentsUI/inputNumber"

class Maintenance extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "maintenance"
        }]);
        
        this.state = {
            ...props,
            dialogOpenMaintenanceHistory:false,
            object: {
                hours:1,
                justify:"",
                user: Session().codUser
            },

        };
    }

    async componentWillMount() {

        const hasPrivilege = await UserService.FindPrivilege(Session().codUser, 1);
        this.setState({ hasPrivilege });
        
    }

    handleChange = e => {
        let object = { ...this.state.object };
        object[e.target.name] = e.target.value;

        this.setState({ object });
    }

    handleChangeInt = e => {
        let object = { ...this.state.object };
        object[e.target.name] = e.target.valueAsNumber;

        this.setState({ object });
    }

    dismissModal() {
		this.setState({ confirmationModalOptions: { open: false } });
	}

    start = _ => {
        let object = { ...this.state.object }

        Service.PostCountExtend(object).then(count => {
            if(count === 0 ){
                Toast.error("Nenhum templates encontrado dentro do filtro")
                return;
            }

            const msg = "Foram encontrados "+ count +" templates dentro do filtro, Deseja realmente iniciar?"
            this.setState({
                confirmationModalOptions: {
                    type: "danger",
                    message: msg,
                    title: "confirmExecute",
                    open: true,
                    onDismiss: this.dismissModal.bind(this),
                    onClose: this.startConfirmation.bind(this)
                }
            });
        }).catch(_ => {
            Toast.error(Translate(_))
        })

    }

    startConfirmation = _ => {
        let object = { ...this.state.object }
        this.setState({ confirmationModalOptions: { loading: true } });
        Service.PostExecuteExtend(object).then(_ => {
            this.setState({ 
                confirmationModalOptions:{ open: false,loading: false},
                object: {
                    hours:1,
                    justify:"",
                    user: Session().codUser
                }, });
            Toast.success(Translate("success_maintenance"))
        }).catch(_ => {
            Toast.error(Translate("error_maintenance"))
            this.setState({ confirmationModalOptions: { loading: false } });
        })
    }

    handleOpenMaintenanceHistory = () => {
        this.setState({ dialogOpenMaintenanceHistory: true });
    }

    handleDismissMaintenanceHistory = () => {
        this.setState({dialogOpenMaintenanceHistory: false});
    }

    saveDisabled = () => {
        const {
            hours,
            justify
        } = this.state.object;

        if (hours <= 0) 
            return true
        

        if(justify == "" || justify.length <= 5)
            return true;

        return false;
    }
    
    render() {
        const { object,confirmationModalOptions, hasPrivilege, dialogOpenMaintenanceHistory } = this.state;
        return (
            <Fragment>
                <StyledPaper>
                    <InternalPageTitle title={Translate("extend_interfaces")} />
                    <StyledInputLabel >{Translate("maintenancExtend")}</StyledInputLabel>
                    <InternalPageTitle />
                        <Grid2 container spacing={2}>
                            <Grid2 size={2}>
                                <ConectorInputNumber 
                                    label="Prorrogar por:"
                                    onChange={this.handleChangeInt}
                                    required={true}
                                    value={object.hours}
                                    name="hours"
                                    help="Hora(s)"
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 size={8}>
                            <ConectorInputText 
                                label="justify"
                                required={true}
                                placeholder="justify"
                                help={"Informe o motivo para a manutenção"}
                                onChange={this.handleChange}
                                name="justify"
                                value={object.justify}/>
                        </Grid2>
                        <Grid2 container style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                            <StyledButton
                                className="no-margin"
                                variant="contained"
                                disabled={this.saveDisabled()}
                                onClick={this.start}>
                                {Translate("execute")}
                            </StyledButton>
                            {hasPrivilege &&
                                <StyledButton 
                                    variant="contained"
                                    onClick={this.handleOpenMaintenanceHistory}>
                                    {Translate("maintenance_history")}
                                </StyledButton>
                            }
                        </Grid2>
                        
                </StyledPaper>
                    {
                        (() => {
                            if (dialogOpenMaintenanceHistory) {
                               return <MaintenanceHistory name={"extend_interfaces"} onDismiss={this.handleDismissMaintenanceHistory.bind(this)} />
                            }
                        })()
                    }
                <ConectorDialogConfirmation
					dialogOptions={confirmationModalOptions}
				/>
            </Fragment>
        );
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
