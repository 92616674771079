import React from 'react'
import { PulsatingImageContainer, StyledAvatar, ImageWithTooltip } from "../../../../componentsUI/styledComponents/styledVersionModal"

class InfoVersionButton extends React.Component {
  render() {
    return (
      <ImageWithTooltip title="Nova versão do worker disponível" arrow>
        <PulsatingImageContainer>
          <StyledAvatar>
            <img
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: 'auto',
                zIndex: 1,
              }}
              src="/static/images/warninIcon.svg"
              alt="Icon"
            />
          </StyledAvatar>
        </PulsatingImageContainer>
      </ImageWithTooltip>
    );
  }
}

export default InfoVersionButton;
