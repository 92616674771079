import React, { Component, Fragment } from 'react'
import ModalVersion from '../generic/modalVersion'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import version from "../generic/version"
import { StyledVersionText } from "../../../../componentsUI/styledComponents/styledVersionModal"

class Button extends Component {
    constructor(props) {
        super(props)  

        this.state = {
            ...props.appState,
            openVersionModal: false,
            versions: "",
            vInfos: []
        }
    }
    async componentWillMount() {
        const { system } = this.props;
        const lastVersion = await version.getVersion(system)
        this.setState({ versions: lastVersion });
        
    }
    handleOpenModal = () => {
        this.setState({ openVersionModal: true });
    }

    handleDismissModal = () => {
        this.setState({ openVersionModal: false });
    }

    componentWillReceiveProps({ appState }) {
        this.setState({ ...appState });
    }
    getSystemName = (system) => {
        switch (system) {
            case "Portal":
                return "Portal"
            case "Worker":
                return "Worker"
            case "MicroBack":
                return "Microserviço de Backend"
            case "MicroFront":
                return "Microserviço de Frontend"
            case "MicroDash":
                return "Microserviço de Dash"
            default:
                break;
        }
    }

    render() {
        const { openVersionModal } = this.state;
        const { system } = this.props;
        const {versions} = this.state;
        var vatual = versions

        return (
            <Fragment>
                <div className={'btnVersion'} onClick={this.handleOpenModal}>
                    <div>
                        <Typography>
                            <StyledVersionText>
                                <Link style={{textDecoration: 'none'}} color="inherit">
                                    {this.getSystemName(system) +": "+ vatual}
                                </Link>
                            </StyledVersionText>
                        </Typography>
                    </div>
                </div>

                {
                    (() => {
                        if (openVersionModal) {
                            return <ModalVersion  system={system} onDismiss={this.handleDismissModal.bind(this)} />
                        }
                    })()
                }
            </Fragment>
        )
    }
}

export default Button