import React, {Component} from "react";
import Translate from "../../../../i18n/translate";
import StyledChipInterface from "../../../../componentsUI/styledComponents/styledChipInterface"
import {
	Grid2,
	Avatar,
	Tooltip,
	Typography
} from "@mui/material"

class EventsItems extends Component {
	constructor(props) {
		super(props);
		this.state = {...props}
	}

	componentWillReceiveProps = ({ disableEventComponent, title, events }) => {
		this.setState({title, events, disableEventComponent})
	}

	onDrag = (eventInfo, event) => event.dataTransfer.setData("eventInfo", JSON.stringify(eventInfo));

	render() {
		const { title, events, position, disableEventComponent } = this.state

		if(disableEventComponent) return

		return (
			<Grid2 style={title === "modifiers" || title === "banks" ? {"marginTop": "10px"} : {}}>
				<Grid2 size={2}>
					<Typography gutterBottom style={{"fontSize": "1rem", "color": "#6F8296"}}>{Translate(title)}</Typography>
				</Grid2>
				<Grid2 size={14}>
				<Grid2
					container
					direction="column"
					justifyContent="center"
					alignItems="stretch"
					sx={{
						backgroundColor: "#fff",
						border: "1px solid #b8c6d6",
						borderRadius: "20px",
						padding: "7px"
					}}>
						{
							events.map(event => (
								<Grid2 key={`event-item-${event.description}`}>
									<Tooltip placement={position === "side" ? "top" : "right"} title={ <h3>{event.label}</h3> }>
										<StyledChipInterface 
											sx={{width: "99%"}} 
											avatar={(() => {
												switch (title) {
													case "modifiers":
														return <Avatar 
															alt={event.label} 
															src={`/static/images/events/${event.description}.png`} 
															style={{"borderRadius": "0", "marginLeft": "18px", "marginRight": "-4px"}} />
													case "banks":
														return <Avatar 
															alt={event.label} 
															src={`/static/images/banksLogos/${event.description}.png`} 
															style={{"borderRadius": "8px", "marginLeft": "18px", "marginRight": "-4px"}} />
													default:
														return <Avatar 
															alt={event.label} 
															src="/static/images/conector-web-img.png"
															style={{"borderRadius": "0", "marginLeft": "18px", "marginRight": "-4px"}} />
												}
										})()} label={event.label} draggable={true} onDragStart={this.onDrag.bind(this, {
											codBank: event.codBank,
											codConnectionType: event.codConnectionType,
											codEvent: event.codEvent,
											codConnectionMode: event.codConnectionMode,
											label: event.label
										})} key={event.description} />
									</Tooltip>
								</Grid2>
							))
						}
					</Grid2>
				</Grid2>
			</Grid2>
		)
	}
}

export default EventsItems;
