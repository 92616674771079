import request from '../../utils/request';
import Session from '../../utils/session-info';

export default {
    DeleteInterface: (codInterface, codInstance) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/delete/${codInstance}/${codInterface}`,
            {
                ...request.deleteOptions()
            }
        ).then(res => { return res })
    },
    CopyInterface: (idNewInstance, codNewSchedule, idInterface, newInterfaceName, codNewConnections) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/copy/`,
            {
                ...request.postOptions(),
                body: JSON.stringify({
                    codDestinyInstance: idNewInstance,
                    codSchedule: codNewSchedule,
                    codInterface : idInterface,
                    nameCopyInterface: newInterfaceName,
                    codConnections: codNewConnections
                })
            }
        ).then(res => { return res })
    },
    ActivateInterface: (codInterface, codInstance, active) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/activate/${codInstance}/${codInterface}/${active}`,
            {
                ...request.postOptions()
            }
        ).then(res => { return res })
    },
    GetAllInterfacesByInstance: codInstance => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/instances/${codInstance}/interfaces`,
            request.getOptions()
        )
            .then(response => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                }
                return response.json();
            })
    },
    GetInterface: id => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/instances/getByIdInterface/${id}`,
            request.getOptions()
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText);
            }
            return res.json();
        })
    }, 
    GetUserPermissionInterface: () => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        })
            .catch((error) => {
                console.error(error);
            });
    },
    GetUserPermissionEditInterface: () => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/userPermissionEdit`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        })
            .catch((error) => {
                console.error(error);
            });
    },
    PostInterface: object => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/insert`,
            {
                ...request.postOptions(),
                body: JSON.stringify(object)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText)
            }
            return res.json();
        })
    },
    PutInterface: object => {
        return request.executeRequest(
            request.baseUrl + 'interfaces/edit',
            {
                ...request.putOptions(),
                body: JSON.stringify(object)
            }
        );
    },
    GetSchedules: _ => {
        return request.executeRequest(
            `${request.baseUrl}schedules/instances/${Session().codInstance}/schedules`,
            request.getOptions()
        ).then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
    },
    GetLogs: (codInterface, page) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/logsByInterface/${Session().codInstance}/${codInterface}/${page}`,
            request.getOptions()
        ).then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
    },
    GetLogsFiltered: (codInterface, dateInicial, dateFinal, page, Error, Success, Warning, withFilterDate) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/logsByInterface/${Session().codInstance}/${codInterface}/${dateInicial}/${dateFinal}/${page}/${Error}/${Success}/${Warning}/${withFilterDate}`,
            request.getOptions()
        ).then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
    },
    GetInstances: () => {
        return request.executeRequest(
            `${request.baseUrl}instances/companies/${Session().codCompany}/instances`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetConnections: (codInterface) => {
        return request.executeRequest(
            `${request.baseUrl}connections/${codInterface}`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetHistoricInterfacesStatus: codInterface => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/gethistoricinterfacesstatus/${codInterface}`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetCompanyInstancesWithPermissionToCopy: () => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/getcompanyinstanceswithpermissiontocopy`,
            request.getOptions()
        ).then(res => res.json());
    },
    InterfaceModes: [
        { label: 'Desenvolvimento', value: 1 },
        { label: 'Produção', value: 2 },
    ],
}