import React, {
    Component,
    Fragment
} from "react";
import Toast from "../../../components/toast/toast";
import { StyledButtonLogin, ResetPasswordFormStyled, InputTextStyled } from "../../../componentsUI/styledComponents/styledLogin"
import ConectorInputText from "../../../componentsUI/inputText"
import ConectorInputPassword from "../../../componentsUI/inputPassword"
import { Grid2 } from "@mui/material";

class ForgotYourPasswordForm extends Component {
    componentWillMount() {
        if (localStorage.hasOwnProperty("loggedOutByInvalidToken")) {
            Toast.info("userLoggedInAnotherSession", true)
            localStorage.removeItem("loggedOutByInvalidToken");
        }

        if (localStorage.hasOwnProperty("expiredAuth")) {
            setTimeout(Toast.info("expired_auth"), 2000);
            localStorage.removeItem("expiredAuth");
        }
    }

    onChange = ({target: {name, value}}) => {
        this.props.updateValues(name, value);
    }

    onKeyPress = ({key}) => {
        if (key === "Enter") {
            this.props.makeLogin();
        }
    }

    render() { 
        const {
            password,
            user,
            isButtonDisabled 
        } = this.props; 

        return ( 
            <Fragment>
                <Grid2 sx={ResetPasswordFormStyled}>
                    <Grid2 size={16} sx={InputTextStyled}>
                        <Grid2>
                            <ConectorInputText 
                                label={"Login"}
                                name={"user"}
                                startIcon={"person"}
                                value={user}
                                required={true}
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}/>
                        </Grid2>
                        <Grid2 sx={{marginTop: '5px'}}>
                            <ConectorInputPassword 
                                label="password"
                                name="password"
                                required={true}
                                startIcon="lock"
                                endIcon={true}
                                value={password}
                                type={false}
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}/>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={16}>
                        <StyledButtonLogin 
                            fullWidth 
                            variant="outlined"
                            onClick={this.props.makeLogin}
                            disabled={isButtonDisabled}>
                        Login
                        </StyledButtonLogin>
                    </Grid2>
                </Grid2>

                <Grid2 container spacing={2}>
                    <Grid2 size={16}>
                        <StyledButtonLogin  
                            onClick={this.props.setForgotPassword} 
                            fullWidth 
                            variant="outlined" 
                            className={"forgotPassword"}>
                            Esqueceu a sua senha?
                        </StyledButtonLogin>
                    </Grid2>
                </Grid2>
            </Fragment>
        );
    }

}

export default ForgotYourPasswordForm;