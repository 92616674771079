import request from "../../utils/request"

export default {
    GetAccessReleases: () => {
        return request.executeRequest(
            `${request.baseUrl}profilessecurity/userProfilesByInstance`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetUserPermissionAcessRelease: () => {
        return request.executeRequest(
            `${request.baseUrl}profilessecurity/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        })
            .catch((error) => {
                console.error(error);
            });
    },
    GetProfiles: () => {
        return request.executeRequest(
            `${request.baseUrl}profilessecurity/allProfiles`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetUserPermissionAcessRelease: () => {
        return request.executeRequest(
            `${request.baseUrl}profilessecurity/userProfile/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        })
            .catch((error) => {
                console.error(error);
            });
    },
    DeleteAccessReleases: (listToDelete) => {
        return request.executeRequest(
            `${request.baseUrl}profilessecurity/deleteUserProfile`,
            {
                ...request.deleteOptions(),
                body: JSON.stringify(listToDelete)
            }
        );
    }
}