import React from "react";
import Toast from "../components/toast/toast";
import Translate from '../i18n/translate';
import { HashRouter, Routes, Route } from "react-router-dom";

import LoginPage from "./login/login";
import Home from "./home/home";
import MyAccount from "./my-account/my-account";
import Users from "./users/users";
import UsersDetails from "./users/user-details";
import Instance from "./instances/instances";
import InstanceDetails from "./instances/instance-details";
import Company from "./companies/companies";
import CompanyDetails from "./companies/company-details";
import AccessReleases from "./access-releases/access-releases";
import AccessReleaseDetails from "./access-releases/access-release-details";
import Profile from "./profiles/profiles";
import Interfaces from "./interfaces/interfaces";
import InterfacesDetails from "./interfaces/interface-details";
import InterfacesLogs from "./interfaces/interfaces-logs/interfaces-logs";
import InterfacesEvents from "./interfaces/interfaces-events/interfaces-events";
import Wizard from "./wizard/wizard";
import Schedules from "./schedules/schedules";
import ScheduleDetails from "./schedules/schedule-details";
import Connections from "./connections/connections";
import ConnectionsDetalhe from "./connections/connections-details";
import Worker from "./worker/worker";
import AttemptsOLD from "./attempts_old/attempts_old"
import Attempts from "./attempts/attempts"
import SumarryLogs from "./adm/summary-logs/summary-logs";
import TemplateHistory from "./adm/template-history/template-history";
import PermissionsPanel from "./permissions-panel/permissions-panel";
import BankAccounts from "./bank-registration/bank-accounts";
import AddEditBankAccount from "./bank-registration/add-edit-bank-account";
import CustomerManagement from "./customer-management/customer-management";
import permissionsPanelService from "./permissions-panel/permissions-panel-service"
import Maintenance from "./adm/maintenance/maintenance";
import ExtendInterfaces from "./adm/maintenance/extend_interfaces";

const ConectorRoutes = () => {
    return (
    <HashRouter>
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login/:passwordChangeToken" element={<LoginPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/myaccount" element={<MyAccount />} />
            <Route path="/worker_panel" element={<Worker />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/add" element={<UsersDetails />} />
            <Route path="/instances" element={<Instance />} />
            <Route path="/instances/:id" element={<InstanceDetails />} />
            <Route path="/companies" element={<Company />} />
            <Route path="/companies/:id" element={<CompanyDetails />} />
            <Route path="/accessrelease" element={<AccessReleases />} />
            <Route path="/accessrelease/:id" element={<AccessReleaseDetails />} />
            <Route path="/profilessecurity" element={<Profile />} />
            <Route path="/interfaces" element={<Interfaces />} />
            <Route path="/interfaces/interface/:id" element={<InterfacesDetails />} />
            <Route path="/interfaces/logs/:id" element={<InterfacesLogs />} />
            <Route path="/interfaces/events/:id" element={<InterfacesEvents />} />
            <Route path="/wizard" element={<Wizard />} />
            <Route path="/schedules" element={<Schedules />} />
            <Route path="/schedules/:id" element={<ScheduleDetails />} />
            <Route path="/connections" element={<Connections />} />
            <Route path="/connections/:id" element={<ConnectionsDetalhe />} />
            <Route path="/attempts" element={<Attempts />} />
            <Route path="/summary_logs" element={<SumarryLogs />} />
            <Route path="/template_history" element={<TemplateHistory />} />
            <Route path="/permissions_panel" element={<PermissionsPanel />} />
            <Route path="/bank_accounts" element={<BankAccounts />} />
            <Route path="/bank_accounts/:codBank" element={<AddEditBankAccount />} />
            <Route path="/bank_accounts/:codBank/:idAccount" element={<AddEditBankAccount />} />
            <Route path="*" element={<Home />} />
            <Route path="/customer_management" element={<CustomerManagement />} />
            <Route path="/start_interfaces" element={<Maintenance />} />
            <Route path="/extend_interfaces" element={<ExtendInterfaces />} />

            <Route path="/attempts_old" element={<AttemptsOLD />} />
        </Routes>
    </HashRouter>)
}

if (window.location.hash === '#/' || window.location.hash === '')
    window.location.hash = '#/'

window.addEventListener('hashchange', () => {
    const pathFragments = window.location.hash.split("/")
    const baseCurrentUrl = pathFragments[1]

    if (baseCurrentUrl != '') {
        permissionsPanelService.GetUserPermissions(baseCurrentUrl)
        .then(res => {
            if(res.status == 401) {
                Toast.error(Translate("notHavePermissionToAccess"));
                window.location.replace('#/')
            }
        })
    }
});

export default ConectorRoutes;