import styled from 'styled-components'

export const BodyDiv = styled.div`
  && {
    input:-internal-autofill-previewed, input:-internal-autofill-selected {
        background-color: #fff !important;
        background-image: none !important;
        color: #009CDF !important;
    }
  }
`

export const RoutesDiv = styled.div`
  && {
    padding-top: 115px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    &.open { margin-left: 240px; }
    &.closed { margin-left: 0px; }
  }
`