import React, { Component } from "react";
import Translate from "../../../i18n/translate";
import GenButton from "./generic/button";
import { DialogContent, DialogActions } from "@mui/material";
import { Grid2 } from "@mui/material";
import { StyledDialog, StyledDialogTitle } from "../../../componentsUI/styledComponents/styledDialog"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"

class AllVersion extends Component {
    constructor(props) {
        super(props);
    }

    renderVersion = () => {
        return (
            <div>
                <GenButton
							variant="contained"
                            system="Portal"
							onClick={_ => { this.handleClick }}>{"Portal"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="Worker"
							onClick={_ => { this.handleClick }}>{"Worker"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="MicroBack"
							onClick={_ => { this.handleClick }}>{"MicroBack"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="MicroFront"
							onClick={_ => { this.handleClick }}>{"MicroFront"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="MicroDash"
							onClick={_ => { this.handleClick }}>{"MicroDash"}
				</GenButton>
            </div>
        );
    };
    render() {
        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={true}
                disableBackdropClock={true}
                fullWidth={true}
                maxWidth={"sm"}>
                <StyledDialogTitle className="info">
                    {Translate("current_versions")}
                </StyledDialogTitle>
                <DialogContent>
                    <Grid2 container={true} spacing={24}>
                        <Grid2 item={true} xs={24} sm={24} md={24} lg={12} xl={12}>
                            {this.renderVersion()}
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onDismiss}>
                        {Translate("close")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default AllVersion;
