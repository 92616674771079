
import * as types from './action-types';

export const setAccessLevel = (accessLevel) => ({
    type: types.SET_ACCESS_LEVEL,
    accessLevel
});

export const setInputSelected = (input) => ({
    type: types.SET_INPUTSELECTED,
    input
});

export const setCompanySelected = (company, desc) => ({
    type: types.SET_COMPANY_SELECTED,
    company,
    desc
});

export const setInstanceSelected = (instance, desc) => ({
    type: types.SET_INSTANCE_SELECTED,
    instance,
    desc
});