import styled from 'styled-components'
import { Chip } from '@mui/material'

const StyledChipInterface = styled(Chip)`
  && {
    cursor: grab; 
    margin: 2px;
    border-radius: 20px;
    min-width: 120px;
    justify-content: flex-start;
    padding: 10px 0px;
    border: 1px solid #B8C6D6;
    background-color: #F5F5F5;
    color: #6F8296;
    flex-basis: 38%;
    flex-grow: 1;
    &:hover { background-color: #F5F5F5; }
    &:active { cursor: grabbing; }
    &.disabled { cursor: no-drop; opacity: 0.5; }
    .MuiChip { white-space: normal; }
    @media screen and (min-width: 960px) and (max-width: 1030px) { flex-basis: 100%; }
    @media screen and (min-width: 1400px) { flex-basis: 20%; }
  }
`

export default StyledChipInterface