import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as loginSelector from '../../store/login/reducer'
import { login } from '../../store/login/actions'
import LoginForm from './login-form/login-form'
import ForgotYourPasswordForm from './forgot-your-password-form/forgot-your-password-form'
import LoginService from './login-service'
import PasswordResetForm from './password-reset-form/password-reset-form'
import Toast from '../../components/toast/toast'
import { setBreadcrumb } from '../../store/app-state/actions'
import validator from 'validator'
import hostInfo from '../../utils/host-info'
import { Avatar } from '@mui/material'
import { StyledDiv, StyledPaperLogin, StyledForm } from "../../componentsUI/styledComponents/styledLogin"

class LoginPage extends Component {
    constructor(props) {
        super(props)
        props.setBreadcrumb([]);
        
        this.state = {
            forgotYourPassword: false,
            passwordChangeToken: window.location.hash.substring(8),
            PasswordResetFormPage: false,
            isButtonDisabled: false,
        }
    }

    componentDidMount() {
        const { passwordChangeToken } = this.state;

        if (localStorage.hasOwnProperty("tokenResetExpiredPassword")){
            this.setState({PasswordResetFormPage: true})
        }

        if(passwordChangeToken != '' && passwordChangeToken != undefined){
            this.setState({PasswordResetFormPage: true})
        }
    }

    setForgotPassword() {
        const { forgotYourPassword } = this.state;
        this.setState({ forgotYourPassword: !forgotYourPassword });
    }

    updateValues(name, value) {
        this.setState({ [name]: value });
    }

    recoverPassword() {
        const { user } = this.state;
        if (!user) {
            return;
        }

        LoginService
            .RecoverPassword(user)
            .then(({ ok }) => {
                if (ok) {
                    Toast.success('E-mail enviado com sucesso, verifique sua caixa de entrada e de spam');
                    this.setState({ forgotYourPassword: false });
                } else {
                    Toast.error('Endereço de e-mail inválido');
                }
            });
    }

    makeLogin() {
        const { user, password } = this.state;
        this.setState({ isButtonDisabled: true });
        let userIPResponseTimeout = 10000
        
        hostInfo.GetUserIP(userIPResponseTimeout).then(client => {
            this.props.makeLogin(user, password, client.ip, () => {
                this.setState({ isButtonDisabled: false });
            })
        }).catch(e => {
            this.props.makeLogin(user, password, null, () => {
                this.setState({ isButtonDisabled: false });
            })
        })
    }

    changeUserPassword(token, newPassword, currentPassword) {
        if(this.validatePasswordStrength(newPassword)){
            if(currentPassword){
                LoginService.ResetExpiredPassword(token, newPassword, currentPassword)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.finishPasswordReset("Senha restaurada com sucesso");
                            break;
                        case 207:
                            Toast.error('same_pass')
                        break;
                        case 401:
                            Toast.error("Senha informada está inválida");
                            break;
                        case 403:
                            Toast.error('tokenNotValid')
                            break;
                        default:
                            Toast.error('internal_error')
                            break;
                    }
                })
            } else {
                LoginService.SetUserPassword(token, newPassword)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.finishPasswordReset("Usuário atualizado com sucesso");
                            break;
                        case 423:
                            Toast.error('time_edit')
                            break;
                        case 207:
                            Toast.error('same_pass')
                            break;
                        case 403:
                            Toast.error('tokenNotValid')
                            break;
                        default:
                            Toast.error('internal_error')
                            break;
                    }
                })
            }
        } else Toast.error("password_not_strong");
    }

    validatePasswordStrength = (password) => {
		var allowedToSave = false
  
		if (validator.isStrongPassword(password, {
		  minLength: 8, minLowercase: 1,
		  minUppercase: 1, minNumbers: 1, minSymbols: 1
		})) {
		  allowedToSave = true
		}
		return allowedToSave
	}

    finishPasswordReset(message) {
        this.setState({ passwordChangeToken: null }, () => {
            localStorage.clear()
            window.location.reload();
        })

        Toast.success(message)
    }

    render() {
        const { user, password, forgotYourPassword, passwordChangeToken, PasswordResetFormPage, isButtonDisabled  } = this.state;
        
        return (
            <StyledDiv>
                <StyledForm name="loginForm">
                    <StyledPaperLogin>
                        <Avatar alt="ConectorWebAPI" src="/static/images/conector-web-img.png" />
                        <h1>Conector</h1>
                        {
                            (() => {
                                if (PasswordResetFormPage) {
                                    return <PasswordResetForm
                                        changeUserPassword={this.changeUserPassword.bind(this)}
                                        passwordChangeToken={passwordChangeToken}
                                        finishPasswordReset={this.finishPasswordReset.bind(this)}
                                    />
                                } else {
                                    return forgotYourPassword
                                        ? <ForgotYourPasswordForm user={user}
                                            setForgotPassword={this.setForgotPassword.bind(this)}
                                            updateValues={this.updateValues.bind(this)}
                                            sendEmail={this.recoverPassword.bind(this)}
                                        />
                                        : <LoginForm user={user || ""}
                                            password={password || ""}
                                            updateValues={this.updateValues.bind(this)}
                                            setForgotPassword={this.setForgotPassword.bind(this)}
                                            makeLogin={this.makeLogin.bind(this)}
                                            isButtonDisabled={isButtonDisabled}
                                        />
                                }
                            })()
                        }
                    </StyledPaperLogin>
                </StyledForm>
            </StyledDiv>
        )
    }
}

const mapStateToProps = (state) => {
    const [user, isLoginPending, isLoginSuccess] = loginSelector.getUserInfo(state)
    return { user, isLoginPending, isLoginSuccess }
}

const mapDispatchToProps = (dispatch) => {
    return {
        makeLogin: (username, password, userIP, onError) => { dispatch(login(username, password, userIP, onError)) },
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)