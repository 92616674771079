import React, { Component } from "react";

import {
    DialogActions,
    DialogContent,
    DialogContentText
} from "@mui/material";
import Translate from "../i18n/translate"

import { StyledDialog, StyledDialogTitle } from "./styledComponents/styledDialog"
import Loading from "./loadingSmall"
import CancelConfirmButtons from "./cancelConfirmButtons"

class ConectorDialogConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            open: false
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props.dialogOptions });
    }

    handleClose = _ => {
        if (this.state.onClose) {
            this.state.onClose()
        }
    }

    handleDismiss = _ => {
        if (this.state.onDismiss) {
            this.state.onDismiss()
        }

        this.setState({ open: false })
    }

    render() {
        const props = { ...this.state }

        return (
            <StyledDialog
                open={props.open}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                <StyledDialogTitle className={(props.type)} id="confirmation-dialog-title">
                    {Translate(props.title)}
                </StyledDialogTitle>
                {
                    props.loading ? (
                        <Loading />
                    ) : (
                        <DialogContent>
                        <DialogContentText id="confirmation-dialog-description">
                            {Translate(props.message)}
                        </DialogContentText>
                        </DialogContent>
                    )
                }
                <DialogActions>
                    <CancelConfirmButtons onCancel={this.handleDismiss.bind(this)} onConfirm={this.handleClose.bind(this)} />
                </DialogActions>
            </StyledDialog>
        )
    }
}

export default ConectorDialogConfirmation