import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import * as appStateReducer from "../../../store/app-state/reducer"
import { setBreadcrumb } from "../../../store/app-state/actions"
import Session from "../../../utils/session-info"
import Translate from "../../../i18n/translate"
import { CSVLink } from 'react-csv'
import LogService from "./sumarry-logs-service"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"
import ConectorTable from "../../../componentsUI/conectorTable"
import InternalPageTitle from "../../../componentsUI/internalPageTitle"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import { ButtonDownloadLessThan960, ButtonDownloadGreaterThan960 } from "../../../componentsUI/styledComponents/styledSummaryLogs"

var windows = window.innerWidth;
var ButtonDownload;

if(windows > 960){
	ButtonDownload = ButtonDownloadLessThan960
} else {
	ButtonDownload = ButtonDownloadGreaterThan960
}

class SummaryLogs extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "summarry-logs"
        }]);
        
        this.state = {
            ...props,
            dataSource: [],
            id: props.id,
            gridOptions: {
				multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                hideFilter: false,
				columns: [{
                    title: "company",
                    field: "companyLog"
                }, {
                    title: "instance",
                    field: "instanceLog"
                }, {
                    title: "template",
                    field: "descriptionLog"
                }, {
                    title: "status",
                    field: "statusLog"
                }, {
                    title: "start",
                    field: "dateStartLog",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "end",
                    field: "dateEndLog",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
			}
        };
    }

    componentWillMount = () => {
        const { id,  } = this.state;
        const { desInstance } = Session();
        LogService.GetSummaryLogsByInstance(id).then(dataSource => {
            if(dataSource){
                const statusInterface = {1: "running", 2: "warning", 3: "error", 5: "awaiting"}
                dataSource.map((item) => {
                    item.statusLog = Translate(statusInterface[item.statusLog])
                    item.instanceLog = desInstance
                    delete item.id
                })
                this.setState({ dataSource });
            }
        });
    };

    render() {
        const { dataSource, gridOptions } = this.state;
        
        return (
            <Fragment>
                <InternalPageTitle title={Translate("summarry-logs")} />
                <StyledPaper>
                    <CSVLink data={dataSource} filename={"logResume.csv"} style={{ textDecoration: 'none' }}>
                        <StyledButton variant="outlined">{Translate("downloadReport")}</StyledButton>
                    </CSVLink>
                    <ConectorTable 
                        gridOptions={gridOptions} 
                        dataSource={dataSource}
                    >
                    </ConectorTable>
                </StyledPaper>
            </Fragment>
        );
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryLogs);
