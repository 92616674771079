import React, { Component, Fragment } from 'react'
import Translate from '../i18n/translate'
import Title from "../componentsUI/styledComponents/styledPageTitle"

class PageTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.appState
        }
    }

    componentWillReceiveProps = props => {
        let state = {...this.state, ...props.appState, openDrawer: props.appState.openDrawer}
        this.setState(state)
    }

    render() {
        
        const { breadcrumb, openDrawer } = this.state

        if (!breadcrumb || !breadcrumb.length) {
            return ( <Fragment></Fragment> )
        }

        /*
            Caso remover a seção principal do breadcrumb, 
            criar uma action e setar a prop na store.
        */
        let title = breadcrumb[1].label;

        return ( 
            <Title className={ openDrawer ? 'marginLeft' : '' }>
                {Translate(title)}
            </Title>
        )
    }
}

export default PageTitle