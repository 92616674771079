const isString = value => {
    return typeof(value) === 'string'
}

export default {
    isString: isString,
    removeSpecialChars: value => {
        if (isString(value)) {
            return value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        } else {
            return value;
        }
    }
}