import React, { Component, Fragment } from 'react'
import Translate from "../i18n/translate"
import { StyledBreadcrumb } from "./styledComponents/styledBreadcrumb"
import Typography from '@mui/material/Typography'

class Breadcrumb extends Component {
    constructor(props) {
        super(props)
        this.state = { ...props.appState }
    }

    componentWillReceiveProps = props => {
        let state = {...this.state, ...props.appState}
        this.setState(state)
    }

    handleClick = link => {
        if (!link) {
            return;
        }
        window.location.replace(link)
    }

    render() { 
        const { breadcrumb } = this.state;
        if (!breadcrumb || !breadcrumb.length) {
            return (<Fragment></Fragment>);
        }

        return (
            <StyledBreadcrumb>
                { breadcrumb.map((step, index) => {
                    if (index === 0) {
                        return (
                            <Typography key={'breadcrumb' + index}
                                className={step.url ? 'pointer' : ''}
                                onClick={_ => { this.handleClick(step.url) }}> 
                                    {Translate(step.label)}
                            </Typography>
                        )
                    }
                    if (index === 1) {
                        return (
                            <Fragment key={'breadcrumb' + index}>
                                <Typography className={'divider'}>/</Typography>
                                <Typography 
                                    className={step.url ? 'pointer' : ''}
                                    onClick={_ => { this.handleClick(step.url) }}>
                                        {Translate(step.label)}
                                </Typography>
                            </Fragment>
                        )
                    } else {
                        return (
                            <Fragment key={'breadcrumb' + index}>
                                <Typography className={'divider'}>/</Typography>
                                <Typography 
                                    className={step.url ? 'pointer' : ''}
                                    onClick={_ => { this.handleClick(step.url) }}> 
                                        {Translate(step.label)}
                                </Typography>
                            </Fragment> 
                        )
                    }
                }) }
            </StyledBreadcrumb>
        )
    }
}

export default Breadcrumb