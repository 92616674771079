import request from '../../utils/request'
import Session from '../../utils/session-info';

export default {
    GetCompanies: () => {
        return request.executeRequest(
            `${request.baseUrl}globals/getallcompanies`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetCompanyById: (companyId) => {
        return request.executeRequest(
            `${request.baseUrl}companies/get/${companyId}`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetUserPermissionCompany: () => {
        return request.executeRequest(
            `${request.baseUrl}companies/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        })
            .catch((error) => {
                console.error(error);
            });
    },
    PostCompany: (company) => {
        return request.executeRequest(
            `${request.baseUrl}companies/insert`,
            {
                ...request.postOptions(),
                body: JSON.stringify(company)
            }
        ).then(res => res.json())
    },
    PutCompany: (company) => {
        return request.executeRequest(`${request.baseUrl}companies/edit`,
            {
                ...request.putOptions(),
                body: JSON.stringify(company)
            }
        ).then(res => res.json())
    }
}