import styled from 'styled-components'

export const StyledBreadcrumb = styled.div`
  && {
    background-color: #F5F5F5;
    border-bottom: 1px solid #B8C6D6;
    padding: 10px 20px 10px;

    p {
      display: inline-block;
      font-size: 0.7rem;
      color: #B8C6D6;
      &.pointer { cursor: pointer; }
      &.pointer:hover { color: #009CDF; }
      &:last-child { color: #6F8296; }
      &.divider { padding: 0 10px 0 10px; }
    }
  }
`

export const BreadcrumbDiv = styled.div`&& { &.open { margin-left: 240px; } }`