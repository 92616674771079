import request from '../../utils/request';

export default {
  GetProfiles: _ => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/allProfiles`,
      request.getOptions()
    ).then(res => res.json());
  },
  GetProfile: id => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/profileAndFunctionsByCodProfile?codProfile=${id}`,
      request.getOptions()
    ).then(res => res.json());
  },
  DeleteProfile: codProfile => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/deleteProfileAndPermisssions?codProfile=${codProfile}`,
      request.deleteOptions()
    ).then(res => res.json());
  },
  GetFunctions: _ => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/allFunctions`,
      request.getOptions()
    ).then(res => res.json());
  },
  GetProfileFunctions: codProfile => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/allFunctionsByProfile?codProfile=${codProfile}`,
      request.getOptions()
    ).then(res => res.json());
  },
  GetUserPermissionProfile: () => {
    return request.executeRequest(
        `${request.baseUrl}profilessecurity/profile/userPermission`,
        request.getOptions()
    ).then((res) => {
        if (!res.ok) {
            return false;
        }
        return true;
    })
        .catch((error) => {
            console.error(error);
        });
},
  PutProfile: profile => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/editProfile`,
      {
        ...request.putOptions(),
        body: JSON.stringify(profile)
      }
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return Promise.resolve(response.statusText);
    });
  },
  PostProfile: profile => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/insertProfile`,
      {
        ...request.postOptions(),
        body: JSON.stringify(profile)
      }
    )
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      });
  },
  SavePermissions: (codProfile, permissions) => {
    return request.executeRequest(
      `${request.baseUrl}profilessecurity/cleanAndSavePermissions?codProfile=${codProfile}`,
      {
        ...request.postOptions(),
        body: JSON.stringify(permissions)
      })
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response.json();
      }).then((json) => {
        return json
      });
  },
  accessLevels: [
    { codAccessLevel: 1, desAccessLevel: 'Visualização' },
    { codAccessLevel: 4, desAccessLevel: 'Edição' },
    { codAccessLevel: 2, desAccessLevel: 'Gerenciamento' }
    //{ codAccessLevel: 3, desAccessLevel: 'Sem Permissão' }
  ]
} 
