import request from "../../utils/request";

export default {
    FindAll: () => {
        return request.executeRequest(
            `${request.baseUrl}users`,
            request.getOptions()
        ).then(res => res.json());
    },
    Save: (user) => {
        return request.executeRequest(
            `${request.baseUrl}users/add`,
            {
                ...request.postOptions(),
                body: JSON.stringify(user)
            }
        );
    },
    
    GetUserById: (userId) => {
        return request.executeRequest(
            `${request.baseUrl}users/get/${userId}`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetUserPermissionUser: () => {
        return request.executeRequest(
            `${request.baseUrl}users/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        })
            .catch((error) => {
                console.error(error);
            });
    },

    ActivateUser: (codUser) => {
        return request.executeRequest(
            `${request.baseUrl}users/activate/${codUser}`,
            {
                ...request.postOptions()
            }
        ).then(res => { return res })
    },
    FindPrivilege: (userId,typePrivilege) => {
        return request.executeRequest(
            `${request.baseUrl}globals/userprivileges/${userId}/${typePrivilege}`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        })
            .catch((error) => {
                console.error(error);
            });
    },
}
