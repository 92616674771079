import store from '../store/store'
import { getI18next } from '../store/app-state/reducer'

export default (value, lowerCase = false) => {
    const i18next = getI18next(store.getState());
    if (i18next && value) {
        const valWithoutSpaces = value.replace(/ |:/g, '_');
        let translation = i18next.t(valWithoutSpaces);

        if (translation !== valWithoutSpaces) {
            if (lowerCase) {
                translation = translation.toLowerCase();
            }

            return translation;
        } else {
            return value;
        }
    }
    return value;
}