import styled from 'styled-components'
import AppBar from '@mui/material/AppBar'

const StyledHeaderAppBar = styled(AppBar)`
  && {
        z-index: 100;
        box-shadow: 0 0;
        position: fixed;
        background-color: #FFFFFF;
        border-left: 0px solid  #B8C6D6;
        border-bottom: 1px solid #B8C6D6;

        .MuiToolbar-regular {
            min-height: 64px;
        }

        .menuButton {
            margin-left: 10px;
            margin-right: 10px;
            color: #6F8296;
        }

        .groupButtons {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 64px;

            svg { color: #6F8296; }
            
            button {
                padding: 0 20px;
                min-height: 64px;
                border-radius: 0;
                background-color: #F5F5F5;
                &:hover { background-color: #EEEEEE; }
            }

            .btnScenario {
                display: flex;
                padding: 0 25px;
                min-height: 64px;
                align-items: center;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                background-color: #F5F5F5;
                cursor: pointer;
                
                svg {  font-size: 32px; margin-left: 15px; }
                &:hover { background-color: #EEEEEE; }
                
                div {
                    display: flex;
                    flex-direction: column;

                    p {
                        display: inline-block;
                        text-align: left;
                        margin-left: 10px;
                        font-size: 0.725rem;
                        color: #B8C6D6;
                        strong { color: #6F8296; }
                    }
                }
            }
        }
    }
`

export default StyledHeaderAppBar