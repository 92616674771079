import styled from 'styled-components'
import Typography from '@mui/material/Typography'

const Title = styled(Typography)`
    && {
        color: #6F8296;
        font-size: 20px;
        &.marginLeft { margin-left: 170px; }
    }
`

export default Title