import styled from "styled-components";
import { ListItem, IconButton, Drawer } from "@mui/material";
import {
	MdViewModule,
	MdListAlt,
	MdDeviceHub,
	MdGpsFixed,
	MdCropDin
} from "react-icons/md";

const SideBarIcons = {
	"view_module": MdViewModule,
	"list_alt": MdListAlt,
	"device_hub": MdDeviceHub,
	"gps_fixed": MdGpsFixed,
	"crop_din": MdCropDin
}

const StyledDrawer = styled(Drawer)`
	&& {
		.MuiDrawer-paper {
			width: 240px;
			height: 100vh;
			position: fixed;
			z-index: 101;
			white-space: nowrap;
			border-right: 0px solid #B8C6D6;
			background-color: #4C637B;
		}
	}
`

const LogoContainer = styled.div`
	&& {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 0 8px 1px;
		border-right: 0px;
		border-bottom: 0px solid #B8C6D6;
		background-color: #344C67;
		min-height: 64px;

		.logo {
			cursor: pointer;
			position: absolute;
			left: 30px;
			> div, p { float: left; }
			p { 
				margin: 10px 0 0 10px; 
				color: #FFFFFF;
			}
		}
	}  
`

const MainMenuHomeListDiv = styled.div`
	width: 100%;
	max-width: 360px;
	cursor: pointer;
	border-bottom: 0px solid #B8C6D6;
	span, svg { color: #B8C6D6; }
`

const StyledIconButton = styled(IconButton)`
	&& {
		padding: 4px;
		color: #B8C6D6;
		&.hide { display: none; }
		&:hover { background-color: #6F8296; }
	}
`

const SubListItem = styled(ListItem)`
	&& { 
		padding-left: 45px;
		text-align: left;
		svg { width: 0.5em; }
		.MuiListItemText { 
			padding: 0 10px 0 0;
			white-space: normal; 
		}
	}
`

export { SideBarIcons, StyledDrawer, LogoContainer, MainMenuHomeListDiv, StyledIconButton, SubListItem }