import React, {
    Component,
    Fragment
} from "react";
import { Grid2, Button } from "@mui/material"
import { connect } from "react-redux";
import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";
import AttemptsService from "./attempts-service";
import Translate from "../../i18n/translate";
import { CSVLink } from 'react-csv';
import ConectorTable from "../../componentsUI/conectorTable"
import Loading from "../../componentsUI/loading"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

class Attempts extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "attempts"
        }]);

		this.state = {
			loading: true,
			gridOptions: {
				multiSelect: false,
				hideBtnNew: true,
				hideBtnEdit: true,
				hideBtnDelete: true,
				hideBtnCopy: true,
				notSort: true,
				columns: [{
                    title: "datAttempt",
                    field: "datAttempt",
                },{
                    title: "name",
                    field: "desName",
                },{
                    title: "email",
                    field: "desEmail",
                },{
                    title: "status",
                    field: "status"
                },{
                    title: "ip",
                    field: "ip",
                },{
                    title: "country",
                    field: "country",
                },{
                    title: "country_state",
                    field: "country_state",
                },{
                    title: "city",
                    field: "city",
                }]
			}
		}
	}

    componentWillMount() {
		AttemptsService
		.GetAllByAttempts()
		.then((dataSource) => {
			if(dataSource != null){
				dataSource.map(item => {
					switch(item.status) {
						case "login_password_expired":
							item.status = Translate("login_password_expired")
							break;
						case "login_invalid_user":
							item.status = Translate("login_invalid_user")
							break;
						case "login_invalid_password":
							item.status = Translate("login_invalid_password")
							break;
						case "login_user_blocked":
							item.status = Translate("login_user_blocked")
							break;
						case "login_success":
							item.status = Translate("login_success")
							break;
					}
				})

				let dataReport = dataSource.map(item => {
					item.datAttempt = new Date(item.datAttempt).toLocaleString()
					return item
				})

				this.setState({
					dataSource: dataSource,
					dataReport: dataReport,
					loading: false
				});
			}
        });
	}

	render() {
		const {
			loading,
			gridOptions,
            dataSource,
			dataReport
		} = this.state;

		if (loading) { return <Loading /> }

		return (
			<Fragment>
				<InternalPageTitle title={Translate("attempts")} />
                <StyledPaper>
					<Grid2 container spacing={1}>
						<CSVLink data={dataReport} filename={"attempts.csv"} style={{ textDecoration: 'none' }}>
							<StyledButton variant="outlined">{Translate("downloadReport")}</StyledButton>
						</CSVLink>
						<StyledButton
							variant="contained"
							onClick={() => { window.location.replace('#/attempts_old') }}>
							{Translate("Dados antigos")}
						</StyledButton>
					</Grid2>
					<ConectorTable gridOptions={gridOptions} dataSource={dataSource} />
                </StyledPaper>
			</Fragment>
		)
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Attempts);