import React, {
    Fragment,
    Component
} from "react";

import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Avatar,
	Collapse,
	Typography
} from "@mui/material";

import {
	MdExpandLess,
	MdExpandMore,
	MdChevronLeft
} from "react-icons/md";

import { 
	SideBarIcons, 
	StyledDrawer, 
	LogoContainer, 
	MainMenuHomeListDiv, 
	StyledIconButton, 
	SubListItem 
} from "../../componentsUI/styledComponents/styledSideBar"

import { connect } from "react-redux";
import Toast from "../toast/toast";
import Translate from "../../i18n/translate";
import request from "../../utils/request";
import Session from "../../utils/session-info";
import ObjectUtils from "../../utils/object-utils";
import StringUtils from "../../utils/string-utils";
import { setAccessLevel } from "../../store/user-info/actions";
import { setUserPermissions } from "../../store/app-state/actions";
import SideBarService from "./side-bar-service";
import VersionButton from "./version/version-button";
import UserService from "../../routes/users/user-service";

class SideBar extends Component {
	constructor(props) {
        super(props);

		this.state = {
			menuContent: [],
			appState: props.appState,
			userLoggedIn: false
		}
	}

	selectMenuItem = _ => {
		if (this.state.menuContent.length) {
			const location = window.location
			if (location.pathname !== "/") {
				const menuContent = this.state.menuContent;
				for (let i = 0; i < menuContent.length; i++) {
					const menuItem = menuContent[i];
					if (menuItem.children) {
						const selectedItem = menuItem.children.find(child =>
							"/" + child.pathName === location.pathname
						)
						if (selectedItem) {
							this.setState({
								selectedItem: selectedItem.codfunc,
								selectedParent: menuItem.codFunction
							})
							break
						}
					} else {
						if ("/" + menuItem.pathName === location.pathname) {
							this.setState({
								selectedItem: menuItem.codFunction
							})
							break
						}
					}
				}
			} else {
				this.setState({
					selectedItem: null,
					selectedParent: null
				})
			}
		}
	}

	getAllFunctions() {
		SideBarService.GetUserPermissions().then(
			functions => this.setFetchedFunctions(functions)
		);
	}

	handleClick = (item) => {
		this.props.setAccessLevel(item.accesLevel)
		if (item.children) {
			this.setState({ ...this.state, selectedParent: item.codFunction })
		} else {
			if (item.codSuper === 0) {
				this.setState({ ...this.state, selectedParent: item.codFunction, selectedItem: null })
			} else {
				this.setState({ ...this.state, selectedItem: item.codfunc })
			}
			window.location.replace('#/' + item.pathName)
			if (window.innerWidth <= 960) {
				this.handleDrawerToggle()
			}
		}
	}

	handleDrawerToggle = _ => {
		let state = {
			...this.state,
			appState: {
				...this.state.appState,
				openDrawer: !this.state.appState.openDrawer
			}
		}
		this.state.appState.refresh({ ...state })
	}

	handleClose = _ => {
		this.setState({
			...this.state,
			appState: {
				...this.state.appState,
				anchorEl: null
			}
		})
	}

	handleMenu = event => {
		this.setState({
			...this.state,
			appState: {
				...this.state.appState,
				anchorEl: event.currentTarget
			}
		})
	}

	renderMenu = _ => {
		const generateChildren = level => {
			if (level.children) {
				return <Collapse
					in={this.state.selectedParent === level.codFunction}
					timeout="auto"
					unmountOnExit
					key={"menu_" + level.codFunction}>

					<List component="div">
						{
							level.children
									.sort(this.handleSortItem)
									.map(child => {
								return (
									<SubListItem
										button
										style={{textAlign: "left"}} 
										className={
											(this.state.selectedItem === child.codfunc
												&& this.state.selectedParent === child.codSuper)
												? "active"
												: ""
										}
										key={child.codfunc}
										onClick={_ => this.handleClick(child)}>
										<ListItemText primary={Translate(child.desc)} />
									</SubListItem>
								)
							})
						}
					</List>
				</Collapse>
			}
		}

		let menu = this.state.menuContent
				.sort(this.handleSortItem)
				.map(level => {
			return (
				<Fragment key={level.codFunction}>
					<ListItem button
						className={(this.state.selectedItem === level.codFunction
							|| this.state.selectedParent === level.codFunction)
							? "active"
							: ""}
						key={level.codFunction}
						onClick={_ => {
							this.handleClick(
								level
							)
						}}>
						<ListItemIcon>
							{React.createElement(SideBarIcons[level.icon || "crop_din"])}
						</ListItemIcon>
						<ListItemText primary={Translate(level.desc)} />
						{
							level.children
								? (this.state.selectedItem === level.codFunction
									|| this.state.selectedParent === level.codFunction)
									? <MdExpandLess /> : <MdExpandMore />
								: ""
						}
					</ListItem>
					{generateChildren(level)}
				</Fragment>
			);
		});

		return menu;
	}

	handleSortItem = (item1, item2) => {
		if (item1.indPosition > item2.indPosition) {
			return 1;
		} else if (item1.indPosition < item2.indPosition) {
			return -1;
		}

		return 0;
	}

	handleMenuBurguer() {
		if (this.state.appState.openDrawer) {
			return (
				<div>
					<List>
						<MainMenuHomeListDiv>
							<List>{this.renderMenu()}</List>
						</MainMenuHomeListDiv>
					</List>
				</div>
			)
		}
	}

	componentWillReceiveProps({ appState, userLoggedIn }) {
		this.selectMenuItem();

		if (userLoggedIn && this.state.userLoggedIn !== userLoggedIn) {
			this.getFunctions();
		}

		this.setState({ appState, userLoggedIn });
	}

	getFunctions = () => {
		const { codCompany, codInstance } = Session(),
			menuContent = this.state;

		if (!codCompany || !codInstance) {
			if (!menuContent.length || (codCompany && menuContent.length === 1)) {
				this.getNewUserFunctions(codCompany);
			}
		} else {
			this.getAllFunctions();
		}
	}

	getNewUserFunctions = (codCompany) => {
		request.executeRequest(
			`${request.baseUrl}globals/newUserPermissions/${codCompany}/`,
			request.getOptions()
		).then(res =>
			res.json()
		).then(funcs => { this.setFetchedFunctions(funcs, true) })
	}

	setFetchedFunctions = async (itemFunc, newUser) => {
		const accessAdm = await UserService.FindPrivilege(Session().codUser,1)
		const accessMaintenanceStart= await UserService.FindPrivilege(Session().codUser,3)
		const accessMaintenanceExtend = await UserService.FindPrivilege(Session().codUser,4)
		if (newUser) {
			itemFunc = itemFunc.map(func => {
				func.codSuper = 0;
				func.icon = "list_alt";
				return {
					CodProfile: 17,
					codFunction: func.codFunction,
					codAccessLevel: 1,
					codInstance: 0,
					functions: func
				}
			});
		}

		var userPermissions = ObjectUtils.deepCopy(itemFunc);
		this.props.setUserPermissions(itemFunc);
		
		if(accessMaintenanceStart || accessMaintenanceExtend){
			if (!accessMaintenanceStart) {
				itemFunc = itemFunc.filter(func => func.codFunction != 28); 
			}	
			if (!accessMaintenanceExtend) {
				itemFunc = itemFunc.filter(func => func.codFunction != 29);
			}
		}else{
			itemFunc = itemFunc.filter(func => func.codFunction != 27); 
		}
		if(accessAdm){
			itemFunc = itemFunc.filter(func => func.codAccessLevel !== 3 && func.functions.indPosition > 0);
		} else {
			itemFunc = itemFunc.filter(func => func.codAccessLevel !== 3 && func.functions.indPosition > 0 && func.codFunction != 21);
		}

		let menuContent = itemFunc
			.filter(item => item.functions.codSuper === 0)
			.map(item => {
				return {
					codFunction: item.codFunction,
					desc: item.functions.function,
					pathName: StringUtils.removeSpecialChars(item.functions.function).toLowerCase(),
					accessLevel: item.codAccessLevel,
					codSuper: item.functions.codSuper,
					icon: item.functions.icon,
					indPosition: item.functions.indPosition
				}
			})

		itemFunc
			.filter(item => item.functions.codSuper !== 0)
			.forEach(item => {
				let child = {
					codSuper: item.functions.codSuper,
					codfunc: item.functions.codFunction,
					desc: item.functions.function,
					pathName: StringUtils.removeSpecialChars(item.functions.function).toLowerCase(),
					accessLevel: item.codAccessLevel,
					indPosition: item.functions.indPosition
				}

				menuContent.forEach(menuItem => {
					if (menuItem.codFunction === child.codSuper) {
						if (menuItem.children) {
							menuItem.children.push(child)
						} else {
							menuItem.children = [child]
						}
					}
				})
			});

		this.setState({ ...this.state, menuContent }, () => {
			this.selectMenuItem();

			const pathFragments = window.location.hash.split("/");
			const baseUrl = pathFragments[1];
			const pathParam = pathFragments[2];

			if (baseUrl.length) {
				const freeRoutes = ["myaccount"];
				if (this.state.appState.userLoggedIn && freeRoutes.includes(baseUrl)) {
					return;
				}

				const permissionsFunctions = userPermissions.map(up => {
					up.functions.codAccessLevel = up.codAccessLevel;
					return up.functions;
				});
				const selectedFunction = permissionsFunctions.find(f => f.function === baseUrl);
				const freeSubRoutes = [undefined, "logs"];

				if (!selectedFunction || selectedFunction.codAccessLevel === 3 || (!freeSubRoutes.includes(pathParam) && selectedFunction.codAccessLevel === 1)) {
					Toast.error("Você não tem permissão para acessar esse recurso");
					window.location.replace('#/')
				}
			}
		});
	}

	handleDrawerClose() {
		let state = { ...this.state }
		state.appState.openDrawer = false
		this.state.appState.refresh(state.appState)
	}

	render() {
		if (!this.state.appState.userLoggedIn) {
			return (<Fragment></Fragment>)
		}

		return (
			<StyledDrawer
				variant={this.state.appState.drawerTemp}
				anchor={"left"}
				open={this.state.appState.openDrawer}
				onClose={this.handleDrawerToggle.bind(this)}
				ModalProps={{ keepMounted: true }}>
				<LogoContainer>
					<div className={"logo"} onClick={_ => { window.location.replace('#/') }}>
						<Avatar alt={"Conector Web-App"} src="/static/images/conector-web-img.png"/>
						<Typography color="inherit"> CONECTOR </Typography>
					</div>
					<VersionButton
						variant="contained"
						onClick={_ => { this.handleClick }}>
						{Translate("versions")}
					</VersionButton>
					<StyledIconButton
						onClick={_ => { this.handleDrawerClose() }}
						className={window.innerWidth >= 960 ? "hide" : ""}>
						<MdChevronLeft />
					</StyledIconButton>
				</LogoContainer>
				{this.handleMenuBurguer()}
			</StyledDrawer>
		)
    }

}

const mapDispatchToProps = (dispatch) => {
	return {
		setAccessLevel: (accesLevel) => { dispatch(setAccessLevel(accesLevel)) },
		setUserPermissions: permissions => { dispatch(setUserPermissions(permissions)) }
	}
}

export default connect(null, mapDispatchToProps)(SideBar)