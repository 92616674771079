import { Dialog, DialogTitle } from "@mui/material";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
    && {
        z-index: 999 !important;
    }`

const StyledDialogTitle = styled(DialogTitle)`
    && {
        font-size: 20px;
        color: #FFFFFF;
        margin-top: 0;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-left: 0px;

        &.confirmation {
            background-color: #009cdf;
        }

        &.danger {
            background-color: #CD5C5C;
        }

        &.info {
            background-color: #4C637B;
        }
    }
`;

export { StyledDialog, StyledDialogTitle };
